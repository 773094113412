<template>
<article id="page-newList">
  <section class="container">
    <div class="breadcrumb">
      <ol class="w-100 r m-auto">
        <li><router-link to="/" class="home"><a-icon type="environment" />首页</router-link></li>
        <li><a>司法行政法律法规</a></li>
        <li><a>列表</a></li>
      </ol>
    </div>
    <div class="row">
      <div class="col-xs-20 col-left hidden-xs">
        <div id="type">
          <h2><i class="iconfont iconxinwendongtai"></i>司法行政法律法规</h2>
          <dl>
             <Collection
                  name="law_ctype"
      v-slot="{ collection }">
            <dd  :class="{ active: id == item.item_value }"   v-for="item in collection" :key="item.item_value">
              <div>
              <router-link :to="`/law_res/list/${item.item_value}`">
                {{item.item_name}}<a-icon type="right-square" />
                </router-link>
                </div>
            </dd>
             </Collection>
          </dl>
        </div>
      </div>
      <div class="col-xs-20 col-right">
        <div class="screen_box">
          <div class="tabs_search">
                <a-input-search
                  placeholder="请输入关键字搜索"
                  enter-button="搜索"
                  size="large"
                  v-model="keyword"
                  @search="
                          search
                        "
                />
          </div>
        </div>
        <Collection
          name="law"
          :limit="provider.defaultPageSize"
          v-slot="{ collection, count }"
          :query="{ law_ctype: id, law_content: keyword }">
        <div class="tab">
          <ul class="tab-heade">
            <li class="active"><a id="lmmc">{{id ? id: '司法行政法律法规'}}</a></li>
          </ul>
          <div class="tab-content">
              <div class="tab-pane active mb-5 overflow-hidden" id="news_list">
              <ul>
                <li v-for="info in collection" :key="info.post_id">
                  <p><router-link  :to="`/law_res/detail/${info.law_id}`" :title="info.law_title">
                    {{info.law_title}}
                      </router-link>
                      </p>
                  <span>{{ moment(info.created).format('YYYY-MM-DD') }}</span>
                  </li>
              </ul>
            </div>
  <div class="pagetion" id="page">
<a-pagination
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
            </div>
          </div>
        </div>
        </Collection>
      </div>
    </div>
  </section>

</article>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'ArticleList',
  components: {
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      },
      keyword: ''
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    },
    search () {
    }
  },
  activated () {
    this.id = this.$route.params.id
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }
}
</script>
<style scoped src="../../assets/css/list.css"></style>
